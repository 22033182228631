import {map, reduce, find as findFp, getOr, maxBy} from "lodash/fp";
import {oddsToString} from "@atg/utils/strings";
import {gameDefs, type TOP7_PRO} from "@atg-horse-shared/game-defs";
import type {GameType} from "@atg-horse-shared/game-types";
import type {Game, GameInfo} from "@atg-horse-shared/racing-info-api/game/types";
import type {PoolResult} from "../types";

/**
 * Get the shortest user-friendly version of a game type, e.g. "V" instead of "Vinnare"
 *
 * The normal version is `getNameFromGameType`, and a slightly longer version is `getShortNameFromGameType`.
 *
 * @todo consider merging this with `getShortNameFromGameType` and `getMergedPoolsShortName`
 */
export function getMobileNameFromGameType(gameType: GameType | typeof TOP7_PRO): string {
    const gameDef = gameDefs[gameType];
    return gameDef.mobileName || gameDef.shortName || gameDef.name;
}

export function getRelativeIndex(
    baseIndex: any,
    preliminaryInvestment: any,
    property: any,
) {
    const baseIndexPercentSum = getOr(0, property, baseIndex);
    const investmentPercentSum = getOr(0, property, preliminaryInvestment);

    if (baseIndexPercentSum === 0) {
        return 0;
    }

    return 100 * (investmentPercentSum / baseIndexPercentSum);
}

export const getSumOfPercent = (preliminaryInvestment: any, property: any) => {
    const investments = getOr([], property, preliminaryInvestment) || [];

    return reduce(
        (accumulated, current) => accumulated + (current.investmentPercent || 0),
        0,
        investments,
    );
};

export const getHorseName = (startNumber: number, horseData: Array<any> = []) => {
    const horse = findFp({startNumber}, horseData);
    return horse ? horse.name : "";
};

export const getScratchedHorse = (startNumber: number, horseData: Array<any> = []) => {
    const horse = findFp({startNumber}, horseData);
    return horse ? horse.scratched : false;
};

export function findBaseIndex(preliminaryInvestments: any, property: any) {
    return maxBy((item: any) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!item.hasOwnProperty(property)) {
            throw new Error(
                `Encountered incomparable object missing '${property}':` +
                    ` for object with start number ${item.startNumber}`,
            );
        }

        return item.percentUnitSum;
    }, preliminaryInvestments);
}

export const extractComboWinners = (
    poolType: string,
    winners: Array<any>,
): Array<PoolResult> =>
    map((winner) => {
        const displayWinners = winner.combination.map((winnerCombination: any) =>
            winnerCombination > 0 ? winnerCombination : "str",
        );

        const winnerString = displayWinners.join(poolType === "tvilling" ? "/" : "-");
        const value = winner.odds ? oddsToString(winner.odds) : "JACKPOT";
        const betType = winners.indexOf(winner) === 0 ? poolType : "";
        return {betType, winnerString, value};
    }, winners);

// only exported for test
export const getBetTypeFromPoolId = (
    poolId: string | null | undefined,
): string | null | undefined => {
    if (!poolId) return null;
    return poolId.substring(0, poolId.indexOf("_"));
};
/**
 * Guard to check if game is GameInfo
 * @param game
 * @returns true if game is GameInfo
 */

export function isGameInfo(game: Game | GameInfo): game is GameInfo {
    return Boolean("tracks" in game);
}

/**
 * Guard to check if game is GameInfo with startTime
 * @param game
 * @returns true if game is GameInfo with startTime
 */

export function isGameWithStartTime(game: Game | GameInfo): game is GameInfo {
    return Boolean("startTime" in game);
}
