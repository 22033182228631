import {v4 as uuid} from "uuid";
import {fetchAuthorized} from "@atg-shared/auth";
import {
    MEMBER_SERVICE_V4_URL,
    MEMBER_AUTH_URL,
    RCS_SERVICE_URL,
} from "@atg-shared/service-url";
import type {MakeNullable} from "@atg/utils/typescript";
import type {
    ContactInfo,
    ContactOptionsResponse,
    SaveContactOptionsPayload,
} from "@atg-payment-shared/account-settings-types";

export const CONTACT_OPTIONS_URL = `${RCS_SERVICE_URL}/optins`;

// Will be replaced by MEMBER_SERVICE_V4_URL when v4 is safe to use in prod
export const CONTACT_INFO_URL = `${MEMBER_AUTH_URL}/contactinfo`;

export const fetchUserContactOptions = () =>
    fetchAuthorized<ContactOptionsResponse>(CONTACT_OPTIONS_URL);

export const saveUserContactOptions = (params: SaveContactOptionsPayload) =>
    fetchAuthorized<ContactOptionsResponse>(CONTACT_OPTIONS_URL, {
        method: "POST",
        body: JSON.stringify(params),
    });

export type SaveContactInfoPayload = MakeNullable<
    ContactInfo & {businessOperationId: string}
>;

export const saveContactInfo = (contactInfo: SaveContactInfoPayload) => {
    const businessOperationId = uuid();
    const payload: SaveContactInfoPayload = {...contactInfo, businessOperationId};
    return fetchAuthorized(CONTACT_INFO_URL, {
        method: "PUT",
        body: JSON.stringify(payload),
    });
};
