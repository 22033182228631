import Features, {rgMicroFe} from "@atg-shared/client-features";

const rgMicroFeIsActive = Features.isEnabled(rgMicroFe);

export const LIMITS_URL = rgMicroFeIsActive ? "/rg/limits" : "/konto/granser";

export const GAMBLING_HABITS_URL = rgMicroFeIsActive
    ? "/rg/gambling-habits"
    : "/konto/mina-spelvanor";

export const SELF_EXCLUSION_URL = rgMicroFeIsActive
    ? "/rg/self-exclusion"
    : "/konto/sparra";
