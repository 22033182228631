import type {Saga} from "redux-saga";
import {put, call} from "redux-saga/effects";
import {getStatusFromResponse} from "@atg-shared/response-mapping/deprecated_loadingStatus";
import {fetchHarrySubscriptionAvailability} from "./harryAPI";
import {
    requestHarrySubscriptionAvailable,
    receiveHarrySubscriptionAvailable,
    receiveHarrySubscriptionAvailableError,
} from "./harryConfirmActions";

export function* checkHarrySubscriptionAvailability(bet: {
    [key: string]: any;
    // @ts-expect-error
}): Saga<void> {
    yield put(requestHarrySubscriptionAvailable());
    let harrySubscriptionAvailabilityResponse;
    try {
        harrySubscriptionAvailabilityResponse = yield call(
            // @ts-expect-error
            fetchHarrySubscriptionAvailability,
            bet,
        );
    } catch (e: unknown) {
        yield put(
            // @ts-expect-error
            receiveHarrySubscriptionAvailableError(getStatusFromResponse(e.response)),
        );
        return;
    }
    yield put(
        receiveHarrySubscriptionAvailable(
            harrySubscriptionAvailabilityResponse.data.isAvailable,
        ),
    );
}
