import {call} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import atgRequest, {pureFetch} from "@atg-shared/fetch";
import root from "window-or-global";

import {COUPON_SERVICE_URL} from "@atg-shared/service-url";
import type {Coupon, CouponType} from "@atg-horse-shared/coupon-types";
import type {AtgResponse} from "@atg-shared/fetch-types";
import * as CouponUtils from "./coupon";

export function* getCouponById(id: string, isLoggedIn: boolean): SagaIterator<Coupon> {
    let response: AtgResponse<Coupon>;
    if (isLoggedIn) {
        response = yield call(atgRequest, `${COUPON_SERVICE_URL}/${id}`);
    } else {
        response = yield call(
            pureFetch,
            `${root.clientConfig.horse.publicApiUrl}/horse-coupons/api-public/v1/coupons/${id}`,
        );
    }
    return CouponUtils.createCouponFromJSON(response.data);
}

export const fetchCouponByGameId = async (
    gameId: string,
    types: Array<CouponType>,
): Promise<Coupon> => {
    const url = `${COUPON_SERVICE_URL}/game/${gameId}/latest`;

    const response =
        types.length > 0
            ? await atgRequest<Coupon>(`${url}?types=${types.join(",")}`)
            : await atgRequest<Coupon>(url);

    return CouponUtils.createCouponFromJSON(response.data);
};

export const getCoupon = (id: string) =>
    atgRequest<Coupon>(`${COUPON_SERVICE_URL}/${id}`);
