import {combineReducers, type Reducer} from "redux";
import horseFavoritesReducer, {
    type HorseFavorites,
} from "@atg-horse/favorites/domain/horseFavoritesReducer";
import countryReducer, {
    type Country,
} from "@atg-horse-shared/country/domain/countryReducer";
// eslint-disable-next-line @nx/enforce-module-boundaries
import fileBetReducer, {
    type FileBetState,
} from "@atg-horse/bet-history-receipts/src/domain/fileBetReducer";
// eslint-disable-next-line @nx/enforce-module-boundaries
import betSpeedPayoutReducer from "@atg-horse/bet-history-receipts/src/domain/speedPayoutReducer";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {RadarState} from "@atg-horse-shared/radar/domain/radarReducer";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type radarReducer from "@atg-horse-shared/radar/domain/radarReducer";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {HarryBoyFeeReducer} from "@atg-horse-shared/coupon";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {HarryBoyFeeBracket} from "@atg-horse-shared/coupon";
// eslint-disable-next-line @nx/enforce-module-boundaries
import harryReducers from "@atg-horse-shared/horse-harry/domain/harryReducer";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as harryConfirmReducer from "@atg-horse-shared/horse-harry/domain/harryConfirmReducer";
import type {HighlightState} from "@atg-horse-shared/highlights";
import {highlightsReducer} from "@atg-horse-shared/highlights";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {
    ReductionTermsState,
    State,
    ReducedBetsReducer,
} from "@atg-horse/reduced-bets";
// eslint-disable-next-line @nx/enforce-module-boundaries
import quickplayReducer, {
    type QuickplayState,
} from "@atg-horse-shared/video-domain/src/quickplay/quickplayReducer";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {hotGamesReducer, type State as HotGamesState} from "@atg-horse-shared/hotgames";
import {storeApiReducer} from "../storeApiReducer";

export type HorseState = {
    horseFavorites: HorseFavorites;
    proxyFileBet: FileBetState;
    highlights: HighlightState;
    hotGames: HotGamesState;
    quickplay: QuickplayState;
    reducedBets: ReductionTermsState;
    country: Country;
    // ...harryReducers,
    // ...harryConfirmReducer,
    harryBoyFeeBrackets: HarryBoyFeeReducer.HarryBoyFeeBracket;
};
type HorseAsyncType = {
    reducedBets: Reducer<any, any>;
};
export const createHorseReducer = (
    // @ts-expect-error
    asyncReducers: HorseAsyncType = {},
): Reducer<HorseState, any> => {
    const staticReducers = {
        highlights: highlightsReducer,
        hotGames: hotGamesReducer,
        horseFavorites: horseFavoritesReducer,
        proxyFileBet: fileBetReducer,
        speedPayout: betSpeedPayoutReducer,
        quickplay: quickplayReducer,
        country: countryReducer,
        harryBoyFeeBrackets: HarryBoyFeeBracket,
        ...harryReducers,
        ...harryConfirmReducer,
        storeApiReducer,
    };
    return combineReducers<any, any>({...staticReducers, ...asyncReducers});
};

export interface GlobalHorseReducer {
    reducedBets: typeof ReducedBetsReducer;
    radar: typeof radarReducer;
}
export interface GlobalHorseState {
    reducedBets: State;
    radar: RadarState;
}

export default createHorseReducer;
