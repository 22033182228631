import {createSelector} from "reselect";

export const selectModals = (state) => state.modals;
const modalData = (state) => state.modalData;

export const showTwoFactorConfirmation = (state) =>
    state.modals.showTwoFactorConfirmationModal ||
    state.modals.showTwoFactorLoginConfirmationModal;

export const showTwoFactorActivation = (state) =>
    state.modals.showTwoFactorActivationModal;

export const showTwoFactorDeactivation = (state) =>
    state.modals.showTwoFactorDeactivationModal;

export const showUserAgreement = (state) => state.modals.showUserAgreementModal;

export const showChangePassword = (state) => state.modals.showChangePasswordModal;

export const showChangeTempPassword = (state) => state.modals.showChangeTempPasswordModal;

export const showRegisterCompleteModal = (state) =>
    state.modals.showRegisterCompleteModal;

export const showStartInfoModal = (state) => state.modals?.showStartInfo;

export const showConfirmWithdrawalBank = (state) =>
    state.modals.showConfirmWithdrawalBank;

export const showWithdrawRegisterBankModal = (state) =>
    state.modals.showWithdrawRegisterBankModal;
export const showWithdrawDeleteBankModal = (state) =>
    state.modals.showWithdrawDeleteBankModal;
export const showWithdrawEditMobileModal = (state) =>
    state.modals.showWithdrawEditMobileModal;
export const showWithdrawConfirmModal = (state) => state.modals.showWithdrawConfirmModal;

export const showUserGamblingSummaryModal = (state) =>
    state.modals.showUserGamblingSummaryModal;

export const showUpdateContactInformationModal = (state) =>
    state.modals.showUpdateContactInformationModal;

export const showVerifyContactInformationModal = (state) =>
    state.modals.showVerifyContactInformationModal;

export const showReviewContactInformationModal = (state) =>
    state.modals.showReviewContactInformationModal;

export const showAnyContactInformationModal = (state) =>
    showReviewContactInformationModal(state) ||
    showVerifyContactInformationModal(state) ||
    showUpdateContactInformationModal(state);

export const showRgsAtgCheckModal = (state) => state.modals.showRgsAtgCheckModal;

export const showLoginTimeReachedLogoutModal = (state) =>
    state.modals.showLoginTimeReachedLogoutModal;

export const showFifteenMinutesLeftModal = (state) =>
    state.modals.showFifteenMinutesLeftModal;

export const showWelcomeModal = (state) => state.modals.showWelcomeNewUserModal;

export const showImageUploadModal = (state) => selectModals(state).showImageUploadModal;

export const isAccountSettingsEmailModalOpen = (state) => state.modals.showEditEmailModal;

export const isAccountSettingsMobileModalOpen = (state) =>
    state.modals.showEditMobileNumberModal;

export const isTwoFactorConfirmationModalOpen = (state) =>
    state.modals.showTwoFactorConfirmationModal;

export const isTwoFactorActivationModalOpen = (state) =>
    state.modals.showTwoFactorActivationModal;

export const isTwoFactorDeactivationModalOpen = (state) =>
    state.modals.showTwoFactorDeactivationModal;

export const isPanicConfirmModalOpen = (state) => state.modals.showVrPanicConfirmation;

export const isInDepositFlow = (state) => state.modals.showDepositModal;

export const getDepositModalHeader = (state) => state.modalData.depositModalData || {};

export const isEditPushNotificationSettingsModalOpen = (state) =>
    state.modals.showEditPushNotificationSettingsModal;

export const isModalShowing = (state) =>
    Object.values(state.modals).some((value) => value === true);

export const isRetrieveNewInviteCodeModalOpen = (state) =>
    selectModals(state).showRetrieveNewInviteCodeModal;

export const showSearchSport = createSelector(
    selectModals,
    (modalState) => modalState && modalState.showSearchSport,
);

export const showBig9ClearBetslipModal = (state) =>
    selectModals(state).showBig9ClearBetslipModal;

export const showOldBig9StatisticsModal = (state) =>
    selectModals(state).showOldBig9StatisticsModal;

export const showBig9StatisticsModal = (state) =>
    selectModals(state).showBig9StatisticsModal;

export const showBig9QRModal = (state) => selectModals(state).showBig9QrModal;

export const showSearchCasino = (state) => Boolean(state.modals?.showSearchCasino);

export const isDepositLimitReminderOpen = (state) =>
    selectModals(state).showDepositLimitReminder;

export const showDeleteChatMessageModal = (state) =>
    selectModals(state).showDeleteChatMessageModal;

export const showBlockChatMemberModal = (state) =>
    selectModals(state).showBlockChatMemberModal;

export const showReportChatMessageModal = (state) =>
    selectModals(state).showReportChatMessageModal;

export const shouldShowReducedCouponStakeModal = (state) =>
    state.modals?.showReducedCouponStakeModal;

export const shouldShowQuickplayStakeModal = (state) =>
    state.modals?.showQuickPlayStakeGameBetCostModal;

export const shouldShowBetOnScratchedHorseModal = (state) =>
    state.modals?.showBetOnScratchedHorseModal;

export const shouldShowKycQuestionnaireModal = (state) =>
    state.modals?.showKycQuestionnaireModal;

export const shouldShowKycQuestionnaireFinishedModal = (state) =>
    state.modals?.showKycQuestionnaireFinishedModal;

export const showKambiModal = (state) => selectModals(state).showKambiModal;

export const getKambiModalData = (state) => state.modalData.kambiModalData;

export const getDepositLimitModalData = createSelector(
    modalData,
    (data) =>
        (data && data.depositLimitModalData) || {
            isInDepositFlow: false,
            gameInfo: {},
        },
);
