import root from "window-or-global";
import {AlertSeverity} from "atg-ui-components/components/Alert/types";

export const INFO = "INFO";
export const ERROR = "ERROR";
export const SUBMIT_ERROR = "SUBMIT_ERROR";
export const WARNING = "WARNING";
export const SUBMIT_WARNING = "SUBMIT_WARNING";

export type MessageId = string;

export type MessageLevel =
    | typeof INFO
    | typeof ERROR
    | typeof SUBMIT_ERROR
    | typeof WARNING
    | typeof SUBMIT_WARNING;

export type Message = {
    id: MessageId;
    type: string;
    level: MessageLevel;
    text: string;
    context?: any;
};

const generateId = (type: string, level: string) => root.btoa(`${type}_${level}`);

/**
 * Create a "coupon error". This means the coupon can't be placed until the issue has been fixed.
 */
export function error(type: string, text: string, context: any): Message | null {
    if (!text) return null;

    return {id: generateId(type, "error"), type, level: ERROR, text, context};
}

/**
 * Create a "coupon submit error". This means the coupon can't be placed until the issue has been fixed.
 * The error will be shown when the user tries to place the coupon.
 */
export function submitError(
    type: string,
    text: string,
    context: any,
): null | Message | undefined {
    if (!text) return null;

    return {
        id: generateId(type, "submitError"),
        type,
        level: SUBMIT_ERROR,
        text,
        context,
    };
}

/**
 * Create a "coupon warning". The coupon can still be placed.
 */
export function warning(type: string, text: string, context: any): null | Message {
    if (!text) return null;

    return {id: generateId(type, "warning"), type, level: WARNING, text, context};
}

/**
 * Create a "coupon submit warning". The coupon can still be placed.
 * The warning will be shown when the user tries to place the coupon.
 */
export function submitWarning(type: string, text: string, context: any): null | Message {
    if (!text) return null;

    return {
        id: generateId(type, "submitWarning"),
        type,
        level: SUBMIT_WARNING,
        text,
        context,
    };
}

export const isSubmitError = (message: Message): boolean =>
    message.level === SUBMIT_ERROR;

export const isError = (message: Message): boolean => message.level === ERROR;

export const isSubmitWarning = (message: Message): boolean =>
    message.level === SUBMIT_WARNING;

export const isWarning = (message: Message): boolean => message.level === WARNING;

export const levelToType = (level: MessageLevel): AlertSeverity => {
    if (level === WARNING || level === SUBMIT_WARNING) {
        return AlertSeverity.WARNING;
    }

    return AlertSeverity.ERROR;
};
