import * as Redux from "react-redux";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {StartlistSelectors} from "@atg-horse/startlists";
import {trackVariable} from "@atg-shared/analytics";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {type StaticStartlist} from "@atg-horse/startlists";
import {
    BET_DISTRIBUTION_DECIMALS_OFF,
    BET_DISTRIBUTION_DECIMALS_ON,
    COMPACT_VIEW_OFF,
    COMPACT_VIEW_ON,
    DETAILED_LIST,
    NEW_LIST,
    POPULAR_LIST,
    RACE_COMMENTS_OFF,
    RACE_COMMENTS_ON,
    SIMPLE_LIST,
    VERTICAL_STARTLISTS_OFF,
    VERTICAL_STARTLISTS_ON,
} from "../utils/startlistCustomizationConstants";

type TrackStartlistCustomizationProps = {
    currentStartlist: StaticStartlist | undefined;
    isVerticalStartlistsActive: boolean;
    showRaceComments: boolean;
    showCompactStartlist: boolean;
    showBetDistributionDecimals: boolean;
};

const formatStartlistName = (currentStartlistName: string) => {
    switch (currentStartlistName) {
        case "enkel lista":
            return SIMPLE_LIST;
        case "populär":
            return POPULAR_LIST;
        case "detaljerad":
            return DETAILED_LIST;
        default:
            return NEW_LIST;
    }
};

export const trackStartlistCustomization = ({
    currentStartlist,
    isVerticalStartlistsActive,
    showRaceComments,
    showCompactStartlist,
    showBetDistributionDecimals,
}: TrackStartlistCustomizationProps) => {
    if (currentStartlist) {
        const preparedListName = formatStartlistName(currentStartlist.name);
        const userSelectedGlobalOptions = [
            isVerticalStartlistsActive ? VERTICAL_STARTLISTS_ON : VERTICAL_STARTLISTS_OFF,
            showRaceComments ? RACE_COMMENTS_ON : RACE_COMMENTS_OFF,
            showCompactStartlist ? COMPACT_VIEW_ON : COMPACT_VIEW_OFF,
            showBetDistributionDecimals
                ? BET_DISTRIBUTION_DECIMALS_ON
                : BET_DISTRIBUTION_DECIMALS_OFF,
        ];

        const userSelectedStartlistOptions = [
            `startlistInitialPreparedList ${preparedListName}`,
            ...currentStartlist.selectedSettings.startlist,
        ].sort();

        trackVariable({
            userSelectedGlobalOptions,
            userSelectedStartlistOptions,
        });
    }
};

export const useStartlistCustomizationTrackingAnalytics = () => {
    const currentStartlistId = Redux.useSelector(
        StartlistSelectors.getCurrentStartlistId,
    );
    const currentStartlist = Redux.useSelector(
        StartlistSelectors.getCurrentStartlist(currentStartlistId),
    );
    const isVerticalStartlistsActive = Redux.useSelector(
        StartlistSelectors.getIsVerticalStartlistsActive,
    );
    const showRaceComments = Redux.useSelector(StartlistSelectors.getShowRaceComments);
    const showCompactStartlist = Redux.useSelector(
        StartlistSelectors.getShowCompactStartlist,
    );
    const showBetDistributionDecimals = Redux.useSelector(
        StartlistSelectors.getShowBetDistributionDecimals,
    );

    return {
        trackStartlistCustomization: () =>
            trackStartlistCustomization({
                currentStartlist,
                isVerticalStartlistsActive,
                showRaceComments,
                showCompactStartlist,
                showBetDistributionDecimals,
            }),
    };
};
