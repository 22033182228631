import {call, select, takeEvery, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import log, {serializeError} from "@atg-shared/log";
import {deprecated_logEvent} from "@atg-shared/analytics";
import type {BetAction as Bet} from "@atg-horse-shared/bet-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {reducedBetType} from "@atg-horse/reduced-bets";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    SharedBetSelectors,
    SHARED_BET_CREATE_PRODUCT,
    SHARED_BET_SHARE_PRODUCT,
    SHARED_BET_COUPON_PRODUCT,
    isSharedBetCreateProduct,
    isSharedBetShareProduct,
} from "@atg-horse/shared-bet";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {PLAYED_BET} from "@atg-horse/horse-bet";
import {PRO_PURCHASE_FLOW} from "./purchaseFlowCategories";
import * as PurchaseActions from "./purchaseActions";
import * as PurchaseAnalytics from "./purchaseAnalytics";
import * as PurchaseSelectors from "./purchaseSelectors";
import type {Product} from "./products";

export const shouldNotReportAnalytics = (
    product: Product,
    context: {flowCategory: string},
) =>
    isSharedBetCreateProduct(product) ||
    isSharedBetShareProduct(product) ||
    context.flowCategory !== PRO_PURCHASE_FLOW;

export function* reportProductAdd(context: {flowCategory: string}) {
    const product: Product = yield select(PurchaseSelectors.getProduct);
    if (shouldNotReportAnalytics(product, context)) return;
    // @ts-expect-error
    const productAddEvent = yield call(PurchaseAnalytics.productAdd, product, context);
    yield call(deprecated_logEvent, productAddEvent);
}

export function* reportProductCheckout(context: {flowCategory: string}) {
    const product: Product = yield select(PurchaseSelectors.getProduct);
    if (shouldNotReportAnalytics(product, context)) return;
    // @ts-expect-error
    const productCheckoutEvent = yield call(
        PurchaseAnalytics.productCheckout,
        product,
        context,
    );
    yield call(deprecated_logEvent, productCheckoutEvent);
}

export function* getParams(type: string, conditions: {ownedNrShares: number}) {
    switch (type) {
        case SHARED_BET_CREATE_PRODUCT: {
            // @ts-expect-error
            return yield select(SharedBetSelectors.getCreateParams);
        }
        case SHARED_BET_SHARE_PRODUCT: {
            // @ts-expect-error
            return yield select(SharedBetSelectors.getPurchaseParams);
        }
        case SHARED_BET_COUPON_PRODUCT: {
            return {
                nrShares: conditions.ownedNrShares,
            };
        }
        default:
            return null;
    }
}

export function* reportProductPurchase(
    context: any,
    {error, payload: {bet}}: {error: any; payload: {bet: Bet}},
): SagaIterator<void> {
    if (error) return;
    const product: Product = yield select(PurchaseSelectors.getProduct);
    if (!product) return;
    // If bet is of reduced, the batchbet has not been finished yet,
    // and the purchase is not done.
    // @ts-expect-error
    if (bet.type === reducedBetType) return;

    // @ts-expect-error
    const couponId = bet.couponId || (product.coupon && product.coupon.id);
    const sharedConditions = yield select(SharedBetSelectors.getConditions, couponId);
    const params = yield call(getParams, product.type, sharedConditions);
    const conditions = {...sharedConditions, ...params};
    const productWithBet = {...product, bet, conditions};

    if (isSharedBetCreateProduct(product) || isSharedBetShareProduct(product)) {
        const productPurchaseEvent = yield call(
            PurchaseAnalytics.buyShare,
            productWithBet,
            context,
        );
        yield call(deprecated_logEvent, productPurchaseEvent);
    } else {
        const productPurchaseEvent = yield call(
            PurchaseAnalytics.productPurchase,
            productWithBet,
            context,
        );
        yield call(deprecated_logEvent, productPurchaseEvent);
    }

    const productSubscribeEvent = yield call(
        // @ts-expect-error
        PurchaseAnalytics.productSubscribe,
        productWithBet,
        context,
    );
    yield call(deprecated_logEvent, productSubscribeEvent);
}

export function* reportSubscriptionPurchase(
    context: any,
    {
        error,
        payload: {subscriptionResponse: harrySubscription},
    }: {error: unknown; payload: {subscriptionResponse: {harrySubscription: any}}},
): SagaIterator<void> {
    if (error) return;
    const product: Product = yield select(PurchaseSelectors.getProduct);
    const productWithBet = {...product, bet: {harrySubscription}};
    const productSubscribeEvent = yield call(
        // @ts-expect-error
        PurchaseAnalytics.productSubscribe,
        productWithBet,
        context,
    );
    yield call(deprecated_logEvent, productSubscribeEvent);
}

export function* handleProductPurchase(context: any) {
    try {
        const product: Product = yield select(PurchaseSelectors.getProduct);
        if (!product) return;

        // @ts-expect-error
        yield takeEvery(PLAYED_BET, reportProductPurchase, context);
        yield takeLatest(
            // @ts-expect-error
            PurchaseActions.HARRY_SUBSCRIPTION_PURCHASED,
            reportSubscriptionPurchase,
            context,
        );
    } catch (err: unknown) {
        log.error("[PurchaseAnalytics]: handleProductPurchase error", {
            error: serializeError(err),
        });
    }
}

export default function* purchaseAnalyticsSaga({
    payload: {context},
}: {
    payload: {context: any};
}) {
    try {
        yield call(reportProductAdd, context);
        yield call(reportProductCheckout, context);
        yield call(handleProductPurchase, context);
    } catch (err: unknown) {
        log.error("[PurchaseAnalytics]: Error", {error: serializeError(err)});
    }
}
