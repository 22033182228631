import {select, call, put, takeEvery} from "redux-saga/effects";
import {serverTime} from "@atg-shared/server-time";
import * as API from "./extendedStartApi";
import {EXPANDED_PREVIOUS_RESULTS} from "./extendedStartActionTypes";
import * as Actions from "./extendedStartActions";
import {
    getExtendedStartsForRace,
    getIsLoadingExtendedStart,
} from "./extendedStartSelectors";
import type {ExtendedStarts} from "./extendedStart.flow";

export function* fetchPreviousResults({payload: {raceId}}: any) {
    const extendedResults: ExtendedStarts = yield select(
        getExtendedStartsForRace,
        raceId,
    );
    const isLoadingPreviousResults: boolean = yield select(
        getIsLoadingExtendedStart,
        raceId,
    );

    if (isLoadingPreviousResults) return;

    const currentTime = serverTime().valueOf();
    const timeToLiveMilliSeconds = 3600000; // 1 hour

    // we want to fetch an updated version of the stored results if they are older than 1 hour or if we don't have any results at all
    const shoulFetchNewRace = !(
        extendedResults &&
        currentTime - extendedResults.createdAt < timeToLiveMilliSeconds
    );

    if (!shoulFetchNewRace) return;

    yield put(Actions.requestExtendedRace(raceId));

    let results;
    try {
        // @ts-expect-error
        results = yield call(API.fetchPreviousResults, raceId);
    } catch (error: unknown) {
        // @ts-expect-error
        yield put(Actions.receivedExtendedRaceError(raceId, error));
        return; // Don't continue the saga if we didn't get a successful response
    }

    yield put(Actions.receivedExtendedRace(results.data));
}

export default function* PreviousResultsSaga() {
    yield takeEvery(EXPANDED_PREVIOUS_RESULTS, fetchPreviousResults);
}
