import * as React from "react";
import {map, find, intersection, flow, join, includes} from "lodash/fp";
import {Reason} from "@atg-shared/response-mapping/get409ErrorMessage";
import {LIMITS_URL} from "@atg-responsible-gambling-shared/urls";
import {t, Trans} from "@lingui/macro";
import {ErrorCodes} from "./errorCodes";

/**
 * Details about a specific error sent back from backend.
 *
 * [Spec](https://confluence-atg.riada.cloud/display/HDDev/Error+responses+in+frontend-facing+applications)
 */
export interface ErrorItem {
    /** globally unique (across ATG) error code (supposed to be unique, but in reality it is not) */
    code: keyof typeof ErrorCodes;
    details?: {
        // short string specifying more details
        reason?: string;
    };
    // it seems that different services can return reason either next to the code, or in the "details" (e.g. KYC)
    reason?: string;
}

/**
 * A backend error response conforming to our error standard.
 *
 * [Spec](https://confluence-atg.riada.cloud/display/HDDev/Error+responses+in+frontend-facing+applications)
 */
export interface ErrorResponse {
    data: {
        errors: Array<ErrorItem>;
    };
    meta: {
        code: number;
    };
}

const formatErrorCodes = (errorMessages: Array<ErrorItem>) =>
    flow(
        map<ErrorItem, keyof typeof ErrorCodes>((error) => error.code),
        join(", "),
    )(errorMessages);

const depositLimitsNotSetError = (errorResponse: ErrorResponse) => (
    <Trans>
        Ditt spel gick inte igenom. Det beror antingen på att ditt saldo är för lågt,
        överskriden förlustgräns eller att du inte registrerat en insättningsgräns.
        <a href={LIMITS_URL}> Sätt insättningsgräns </a>
    </Trans>
);

const temporarySelfExclusionError = () =>
    t`Ditt konto är tillfälligt spärrat för spel. Vänligen kontakta ATG Kundservice.`;

const poolIsClosedError = (errorResponse: ErrorResponse) =>
    t`Spelet är stängt och kunde inte lämnas in.`;

/**
 * This function transforms an error response into an error message to be displayed for the user.
 * It takes into account multiple error codes sent from backend and displays the correct message based on error code priority.
 * Example: Backend sends [{code: HBS_00101}, {code: HBS_10121}] -> Check if we handle such errors ->
 * If we do return appropriate message -> If not return (Teksiskt fel. (Error code XX))
 * [Spec] https://confluence-atg.riada.cloud/display/HDDev/Error+responses+in+frontend-facing+applications
 */
export const getErrorMessageFromResponse = (
    errorResponse: ErrorResponse,
): React.ReactNode => {
    // error code at the rejection response for KYC blocked users is currently HBS_00101, ie the same as for depositLimitsNotSetError. But we want to show a different message for KYC.
    const kycError = find(
        (error) => error?.details?.reason === Reason.KYC_QUESTIONNAIRE_NOT_SUBMITTED,
        errorResponse?.data?.errors,
    );
    if (kycError) {
        return t`Du behöver besvara en kundkännedomsenkät under Mina pengar på atg.se`;
    }

    // most specific: match user-friendly messages on `data.errors[].code`
    //
    // Note that the order matters here – we only show the corresponding user-friendly message for
    // the most "serious" error.
    const errorCodesByPriority = [
        {
            errorCodes: [ErrorCodes.HBS_00101],
            errorMessageTemplate: depositLimitsNotSetError,
        },
        {
            errorCodes: [ErrorCodes.HBS_00103],
            errorMessageTemplate: temporarySelfExclusionError,
        },
        {
            errorCodes: [ErrorCodes.HBB_10121],
            errorMessageTemplate: poolIsClosedError,
        },
    ];

    // less specific: match user-friendly messages on `meta.code` (HTTP response code)
    //
    // Note that the order matters here – we only show the corresponding user-friendly message for
    // the most "serious" error.
    const httpCodes: any = [];

    const {errorCodes, errorMessageTemplate} =
        find(
            (errorCodeConfig) =>
                intersection(
                    errorCodeConfig.errorCodes,
                    map((error) => ErrorCodes[error.code], errorResponse.data.errors),
                ).length > 0,
            errorCodesByPriority,
        ) ?? {};

    // match found in `errorCodesByPriority`
    if (errorCodes && errorMessageTemplate) {
        return errorMessageTemplate(errorResponse);
    }

    // no match found in `errorCodesByPriority`, but response conforms to new standard (has error codes)
    if (errorResponse.data.errors) {
        return t`Tekniskt fel. (${formatErrorCodes(errorResponse.data.errors)})`;
    }

    const {httpCode, httpMessageTemplate} =
        find(
            (errorCodeConfig) =>
                includes(errorResponse.meta.code, errorCodeConfig.httpCode),
            httpCodes,
        ) ?? {};

    // no match on previous cases, just match on HTTP response code
    if (httpCode && httpMessageTemplate) {
        return httpMessageTemplate(errorResponse.meta.code);
    }

    if (errorResponse.meta.code === 0) {
        return t`Anslutningen till servern bröts. För att kontrollera status på den senaste transaktionen se Mina pengar > Kontohistorik.`;
    }

    // fallback
    return t`Tekniskt fel.`;
};
