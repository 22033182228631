import {put, call, select, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {getStatusFromResponse} from "@atg-shared/response-mapping/deprecated_loadingStatus";
import {AtgAlertTypes} from "@atg-global-shared/alerts-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    CouponActions,
    CouponSelectors,
    CouponSyncSaga,
    CouponApi,
} from "@atg-horse-shared/coupon";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    takeSuccessful,
    PurchaseActions,
    PurchaseSelectors,
} from "@atg-horse-shared/purchase";
import * as ToastActions from "atg-ui-toast/domain/toastActions";
import * as SharedBetActions from "./sharedBetActions";
import * as SharedBetSelectors from "./sharedBetSelectors";
import * as SharedBetApi from "./sharedBetApi";

const NOT_ENOUGH_SHARES = "NOT_ENOUGH_SHARES";

export const getPurchaseErrorMessageByReason = (reason = ""): string => {
    switch (reason) {
        case `BET_ALREADY_PLACED`:
            return "Spelet är redan lagt.";
        case "INSUFFICIENT_FUNDS":
            return "Ditt saldo är för lågt för att lägga spelet.";
        case "DEPOSIT_LIMIT_NOT_SET":
        case "RGS_DEPOSIT_LIMIT_NOT_SET":
            return "Nya regler för spel. Du behöver sätta en insättningsgräns innan du kan lägga ditt spel.";
        case "NAR_EXCLUSION":
        case "RGS_NAR_EXCLUSION":
            return "Ditt konto är spärrat via Spelpaus.se.";
        case "RGS_SELF_EXCLUSION":
        case "SELF_EXCLUSION":
            return "Ditt konto är spärrat för spel.";
        case "RGS_TEMPORARY_SELF_EXCLUSION":
        case "TEMPORARY_SELF_EXCLUSION":
            return "Ditt konto är tillfälligt spärrat för spel. Vänligen kontakta ATG Kundservice.";
        case "RGS_NAR_UNDETERMINED":
        case "NAR_UNDETERMINED":
            return "Tjänsten spelpaus.se svarar inte just nu. Om du inte har stängt av dig för spel hos spelpaus.se vänligen logga ut och in på nytt.";
        case "NETLOSS_LIMIT_EXCEEDED":
        case "RGS_NETLOSS_LIMIT_EXCEEDED":
            return "Du har nått din förlustgräns. Kostnaden för spelet överskrider din satta förlustgräns.";
        case "NOT_ENOUGH_SHARES_FOR_SALE":
            return "Tyvärr hann andelarna sälja slut innan du hann köpa.";
        case "PURCHASE_ERROR_BET_ALREADY_PLACED":
            return "Tyvärr lämnades andelsspelet in precis innan du hann köpa.";
        default:
            return "Ett oförutsett tekniskt fel har inträffat.";
    }
};

export const getPurchaseShareConflictMessage = (response: {
    [key: string]: any;
}): string => {
    const {data} = response;

    switch (data.status) {
        case NOT_ENOUGH_SHARES:
            if (data.totalNrShares === data.totalNrSoldShares)
                return "Tyvärr finns inte längre några andelar tillgängliga för köp i detta andelsspel.";
            return `Det finns inte längre så många andelar du vill köpa tillgängliga. Vill du gå vidare med köp av ${
                data.totalNrShares - data.totalNrSoldShares
            } andelar istället?`;
        default:
            return "Token redan använd";
    }
};

export const getCreateSharedBetResponseStatus = (response: any) =>
    getStatusFromResponse(response, {
        "401": "Felaktigt lösenord",
        "500": "Okänt tekniskt fel",
        "409": getPurchaseErrorMessageByReason(response.data.reason),
    });

export const getPurchaseShareResponseStatus = (response: any) =>
    getStatusFromResponse(response, {
        "400": getPurchaseErrorMessageByReason(response.data.reason),
        "500": "Okänt tekniskt fel",
        "409": response.data.reason
            ? getPurchaseErrorMessageByReason(response.data.reason)
            : getPurchaseShareConflictMessage(response),
    });

const getInviteResponseStatus = (response: any) =>
    getStatusFromResponse(response, {
        "400": "Vänligen kontrollera dina uppgifter",
        "500": "Okänt tekniskt fel",
    });

export function* sharedBetInviteShowConfirmToast(): SagaIterator<void> {
    yield put(
        ToastActions.showToast({
            type: AtgAlertTypes.SUCCESS,
            message: "Inbjudan är skickad",
        }),
    );
}

function* sharedBetInviteFlow({payload, token}: any): SagaIterator<void> {
    const {url, friends, message} = payload;
    try {
        yield call(
            SharedBetApi.inviteFriends,
            {
                url,
                friends,
                message,
            },
            token,
        );
        yield put(PurchaseActions.finishPurchase());
        yield put(SharedBetActions.inviteFriendsSuccess());
        yield call(sharedBetInviteShowConfirmToast);
    } catch (e: unknown) {
        // @ts-expect-error
        const status = getInviteResponseStatus(error.response);
        yield put(SharedBetActions.inviteFriendsError(status.error.message));
    }
}

export function* sharedBetConditions(couponId: string): SagaIterator<void> {
    const response = yield call(SharedBetApi.fetchConditions, couponId);
    yield put(SharedBetActions.fetchConditionsSuccess(response.data));
}

export function* sharedBetConditionsFlow({
    payload,
}: {
    [key: string]: any;
}): SagaIterator<void> {
    try {
        yield call(sharedBetConditions, payload);
    } catch (error: unknown) {
        yield put(SharedBetActions.fetchConditionsError(error));
    }
}

export function* cancelSharedBet({
    payload,
}: SharedBetActions.CancelSharedBetAction): SagaIterator<void> {
    try {
        yield call(SharedBetApi.cancelSharedBet, payload);
        yield put(SharedBetActions.cancelSharedBetSuccess());
    } catch (error: unknown) {
        yield put(SharedBetActions.cancelSharedBetError(error));
    }
}

export function* startInviteModalFlow(): SagaIterator<void> {
    const action = yield call(takeSuccessful, [
        SharedBetActions.SHARED_BET_INVITE_RESPONSE,
        SharedBetActions.SHARED_BET_INVITE_MODAL_FLOW_FINISH,
    ]);
    if (action.type === SharedBetActions.SHARED_BET_INVITE_MODAL_FLOW_FINISH) {
        return;
    }
    yield put(SharedBetActions.inviteFriendsModalFlowFinish());
}

export function* isCouponSynced(coupon: {[key: string]: any}): SagaIterator<boolean> {
    if (!coupon.id) return false;

    try {
        yield call(CouponApi.getCoupon, coupon.id);
        return true;
    } catch (error: unknown) {
        return false;
    }
}

export function* getSyncedCouponId(coupon: {[key: string]: any}): SagaIterator<string> {
    // Check if id for current coupon has be removed by server
    const couponSynced = yield call(isCouponSynced, coupon);

    if (couponSynced) {
        // we will make current coupon a shared coupon.
        return coupon.id;
    }

    // here we either have a totally new coupon or (I assume) removed from the server
    // in any case we want to do a POST to the server and update current visible coupon
    // making it a shared coupon
    // @ts-expect-error
    const syncedCoupon = yield CouponSyncSaga.createRemoteCouponAndSync(coupon);
    return syncedCoupon && syncedCoupon.id;
}

// eslint-disable-next-line require-yield
export function* getReceiptData(product: {[key: string]: any}): SagaIterator<void> {
    const {type: gameType, races} = product.game;
    const {startTime, track} = races[0];
    // @ts-expect-error
    return {
        gameType,
        startTime,
        trackName: track.name,
    };
}

export function* purchaseSharesSharedBet(
    bet: {
        [key: string]: any;
    },
    token: string,
): SagaIterator<void> {
    const response = yield call(SharedBetApi.purchaseShare, bet, token);

    return {
        ...response.data,
        couponId: bet.couponId,
    };
}

export function* createSharedBet(token: string): SagaIterator<void> {
    const product = yield select(PurchaseSelectors.getProduct);
    if (!product) throw new Error("No product found"); // will be handled as a 500 in purchase flow.

    const {cid} = product;
    const coupon = yield select(CouponSelectors.getCoupon, cid);
    const showSystemSizeLimit = yield select(SharedBetSelectors.showSystemSizeLimit);

    const couponId = yield call(getSyncedCouponId, coupon);

    // Update id for confirm and original coupon
    yield put(CouponActions.setCouponId(coupon.cid, couponId));

    const {shareCost, nrShares, totalNrShares, systemSizeLimit} = yield select(
        SharedBetSelectors.getCreateParams,
    );

    const response = yield call(
        SharedBetApi.createSharedBet,
        {
            gameId: coupon.game.id,
            couponId,
            shareCost,
            totalNrShares,
            nrShares,
            systemSizeLimit: showSystemSizeLimit ? systemSizeLimit : undefined,
        },
        token,
    );

    // Fetch the shared bet to toggle button state
    yield call(sharedBetConditions, couponId);

    // Update modified data for both cids to avoid conflicting coupons problem
    yield put(
        CouponActions.couponModifiedTimestampUpdated(coupon.cid, response.data.modified),
    );

    const url = `${location.origin}/?sharedBet=true&couponId=${couponId}`;

    return {
        ...response.data,
        couponId,
        url,
    };
}

export default function* sharedBetSaga(): SagaIterator<void> {
    yield takeLatest(SharedBetActions.SHARED_BET_INVITE, sharedBetInviteFlow);
    yield takeLatest(SharedBetActions.SHARED_BET_CONDITIONS, sharedBetConditionsFlow);
    yield takeLatest(SharedBetActions.SHARED_BET_CANCEL, cancelSharedBet);
    yield takeLatest(
        SharedBetActions.SHARED_BET_INVITE_MODAL_FLOW_START,
        startInviteModalFlow,
    );
}
